import React, { useState, useContext, useEffect } from "react";

import { Link, Redirect, withRouter } from "react-router-dom";

import PsContext from "../../context";
import { Card, Col, Form, Row, Button } from "react-bootstrap";
import axios from "axios";
import { ServiceUrl } from "../../utils/serviceUrl";
import { toast } from "react-hot-toast";
import { Spin } from "antd";
import {
  integerIndMobile,
  lettersOnly,
  lowerCase,
  momentDate,
  upperCase,
  APP_TYPE,
  COLLEGE,
} from "../../utils";
import { LOGIN_FOR } from "../../utils/data";
import PsModalWindow from "../../utils/PsModalWindow";
import MobileNumberExistAlertMessage from "./mobileNumberExistAlertMessage";

const Registration = (props) => {
  const context = useContext(PsContext);

  let courseType = props.match.params.courseType;

  const [loader, setLoader] = useState(false);
  const [validated, setValidated] = useState(false);
  const [captcha, setCaptcha] = useState([]);
  const [showMobileExitAlert, setShowMobileExistAlert] = useState(false);
  const [toVerify, setToVerify] = useState(false);
  const [streamVal, setStream] = useState("");
  const [courseList, setCourseList] = useState([]);

  useEffect(() => {
    loadCaptcha();
  }, []);

  const loadCaptcha = () => {
    setLoader(true);
    let url = "";
    if (APP_TYPE == "SAAS") url = "?sadb=" + COLLEGE;
    axios.get(ServiceUrl.ADMISSION.CAPTCHA + url).then((res) => {
      if (res["data"].status == "1") {
        setCaptcha(res["data"]);
      }
      setLoader(false);
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    setLoader(true);
    axios
      .post(ServiceUrl.ADMISSION.REGISTER, new FormData(form))
      .then((res) => {
        if (res["data"].status == "1") {
          setToVerify(res["data"].token);
        } else {
          if (
            upperCase(res["data"].message) ==
            upperCase("Mobile number already exist")
          )
            setShowMobileExistAlert(true);
          else toast.error(res["data"].message || "Error");

          loadCaptcha();
        }
        setLoader(false);
      });
  };

  const aided = [
    "B.Sc. Computer Science (A)",
    "B.Sc. Mathematics (A)",
    "B.Sc. Physics (A)",
    "B.Sc. Chemistry (A)",
    "B.Sc. Botany (A)",
    "B.Sc. Zoology (A)",
    "B.Com (A)",
    "B.A History  - Tamil Medium (A)",
    "B.A Economics  - Tamil Medium (A)",
  ];
  const unaided = [
    "B.Sc. Farm Science - Tamil Medium (SF)",
    "B.Sc. Visual Communication (SF)",
    "B.Sc. Physical Education (SF)",
    "B.Sc. Criminology and Forensic Science (SF)",
    "B.Sc. Computer Science  (SF)",
    "B.Sc. Chemistry (SF)",
    "B.Sc. Microbiology (SF)",
    "B.C.A (SF)",
    "B.Com (SF)",
    "B.Com Honours (SF)",
    "B.Com Banking & Finance (SF)",
    "B.Com Banking & E-Commerce (SF)",
    "B.Com Corporate Secretaryship (SF)",
    "B.B.A  (SF)",
    "B.B.A Shipping and Logistics (SF)",
    "B.B.A Aviation Management (SF)",
    "B.B.A Digital Marketing (SF)",
    "B.A. Criminology & Police Administration (SF)",
    "B.A. Public Administration - Tamil Medium (SF)",
    "B.A. Tamil (SF)",
    "B.A. English (SF)",
    "B.A. Economics (SF)",
  ];

  const pgaided = ["M.Sc. Mathematics (A)", "M.Com (A)", "M.A Economics (A)"];
  const pgunaided = [
    "M.Sc. Computer Science with AI (SF)",
    "M.Sc. Computer Science (SF)",
    "M.Sc. Physics (SF)",
    "M.Sc. Chemistry (SF)",
    "M.Sc. Botany (SF)",
    "M.Sc. Zoology (SF)",
    "M.Sc. Microbiology (SF)",
    "M.C.A (SF)",
    "M.B.A  (SF)",
    "M.Com (SF)",
    "M.A. Tamil (SF)",
    "M.A. English (SF)",
    "M.A. History (SF)",
  ];

  const maxDate = (type) => {
    if (type == "UG") {
      let y = momentDate(new Date(), "YYYY") - 16;
      let d = momentDate(new Date(), "DD");
      let m = momentDate(new Date(), "MM");
      return momentDate(y + "-" + m + "-" + d, "YYYY-MM-DD");
    } else {
      let y = momentDate(new Date(), "YYYY") - 16;
      let d = momentDate(new Date(), "DD");
      let m = momentDate(new Date(), "MM");
      return momentDate(y + "-" + m + "-" + d, "YYYY-MM-DD");
    }
  };
  const handleStreamChange = (type) => {
    if (applyForValue() == "UG") {
      if (type == "regular") {
        setCourseList(aided);
      } else {
        setCourseList(unaided);
      }
    } else {
      if (type == "regular") {
        setCourseList(pgaided);
      } else {
        setCourseList(pgunaided);
      }
    }
    setStream(type);

    //getCourseList(type);
  };

  const getCourseList = (type) => {
    try {
      setLoader(true);
      setCourseList([]);
      var form = new FormData();
      form.append("stream", type);

      axios.post(ServiceUrl.UG.COURSE_LIST, form).then((res) => {
        if (res["data"].status == "1") {
          setCourseList(res["data"].data);
        } else {
          toast.error(res["data"].message || "Error");
        }
        setLoader(false);
      });
    } catch (error) {
      setLoader(false);
    }
  };

  const applyForValue = () => {
    try {
      let courseType = props.match.params.courseType;
      let s = LOGIN_FOR.find((item) => item.value == lowerCase(courseType));

      if (context.settingValue(s.key) == "1") return upperCase(s.value);

      return "";
    } catch (e) {
      return "";
    }
  };

  if (!courseType) return <></>;
  if (toVerify)
    return <Redirect to={`/mobile-verification?token=${toVerify}`} />;

  return (
    <>
      <div className="container mb-3">
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <Card>
              <Card.Body>
                <h5 className="text-center mt-2 mb-4">Online Registration</h5>
                <Spin spinning={loader}>
                  <Form
                    action=""
                    method="post"
                    noValidate
                    validated={validated}
                    onSubmit={handleFormSubmit}
                  >
                    <input
                      type="hidden"
                      name="academic_year"
                      value={context.settingValue("current_academic_year")}
                    />
                    <input
                      type="hidden"
                      name="sadb"
                      value={APP_TYPE == "SAAS" ? COLLEGE : ""}
                    />
                    <input
                      type="hidden"
                      name="i__captchaCode"
                      value={captcha.code}
                    />

                    <Row>
                      <Col md={12}>
                        <label>
                          Apply for <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          type="text"
                          name="i__applyFor"
                          className="fw-bold"
                          value={applyForValue()}
                          required
                        />
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col md={3}>
                        <label>
                          Initial <span className="text-danger"></span>
                        </label>
                        <Form.Control
                          type="text"
                          name="i__initial"
                          className="fw-bold text-uppercase"
                          placeholder="Initial"
                          onKeyPress={lettersOnly}
                        />
                      </Col>
                      <Col md={9}>
                        <label>
                          Name of the candidate{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          type="text"
                          name="i__name"
                          className="fw-bold text-uppercase"
                          placeholder="Name of the Candidate"
                          onKeyPress={lettersOnly}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Candidate name is required
                        </Form.Control.Feedback>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col md={12}>
                        <label>
                          Candidate mobile number
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          type="number"
                          name="i__mobile"
                          className="fw-bold"
                          placeholder="Candidate mobile number"
                          onKeyPress={integerIndMobile}
                          onWheel={(e) => e.target.blur()}
                          required
                        />
                        <label style={{ fontSize: "11px" }}>
                          ஒரு தொலைபேசி எண்ணை ஒரு முறை மட்டுமே பதிவு செய்ய
                          இயலும்.
                        </label>
                        <Form.Control.Feedback type="invalid">
                          Candidate mobile number is required
                        </Form.Control.Feedback>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col md={12}>
                        <label>
                          Candidate Mail id
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          type="email"
                          name="i__email"
                          className="fw-bold"
                          placeholder="Candidate Mail id"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Candidate mail id is required
                        </Form.Control.Feedback>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col md={12}>
                        <label>
                          Candidate Date of Birth
                          <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          type="date"
                          name="i__dob"
                          className="fw-bold"
                          max={maxDate(applyForValue())}
                          min={
                            applyForValue() == "UG"
                              ? "1999-06-01"
                              : "1960-06-01"
                          }
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          {applyForValue() == "UG"
                            ? "Candidate date of birth is required and data of birth should be greater than 1-June-1999"
                            : "Candidate date of birth is required and data of birth should be greater than 1-June-1960"}
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                    {context.settingValue("register_preferred_course") == 1 && (
                      <>
                        <Row className="mt-3">
                          <Col md={12}>
                            <label>
                              School Last Studied , Place
                              <span className="text-danger">*</span>
                            </label>
                            <br></br>
                            <Form.Control
                              type="text"
                              name="i__schoolname"
                              className="fw-bold "
                              placeholder="School Last Studied"
                              onKeyPress={lettersOnly}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              School name is required
                            </Form.Control.Feedback>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col md={12}>
                            <label>
                              Stream
                              <span className="text-danger">*</span>
                            </label>
                            <br></br>
                            <Form.Check
                              type="radio"
                              inline
                              name="stream"
                              label="Aided"
                              value="regular"
                              onChange={(e) =>
                                handleStreamChange(e.target.value)
                              }
                              defaultChecked={
                                streamVal == "regular" ? true : false
                              }
                              required
                            />
                            <Form.Check
                              type="radio"
                              inline
                              name="stream"
                              label="Self Finance"
                              value="self"
                              onChange={(e) =>
                                handleStreamChange(e.target.value)
                              }
                              defaultChecked={
                                streamVal == "self" ? true : false
                              }
                              required
                            />
                          </Col>
                        </Row>

                        <Row className="py-2 border-bottom">
                          <Col md={12}>
                            <label>
                              Preferred Programme{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Form.Control
                              as="select"
                              name="preferred_course"
                              className="fw-bold form-select form-select-sm"
                              required
                            >
                              <option value="">-Select-</option>
                              {courseList &&
                                courseList.length > 0 &&
                                courseList.map((item) => (
                                  <option value={item}>{item}</option>
                                ))}
                            </Form.Control>
                          </Col>
                        </Row>
                      </>
                    )}

                    <Row className="mt-3">
                      <Col md={5} xs={12} sm={12}>
                        <img src={captcha && captcha.img} />
                        <Button
                          type="button"
                          className="ms-2"
                          variant="white border"
                          onClick={(e) => loadCaptcha()}
                        >
                          <i className="fa-solid fa-arrow-rotate-right"></i>
                        </Button>
                      </Col>
                      <Col md={7} xs={12} sm={12}>
                        <Form.Control
                          type="text"
                          name="i__captcha"
                          required
                          className="fw-bold"
                          placeholder="Enter captcha code"
                        />
                        <Form.Control.Feedback type="invalid">
                          Captcha code is required
                        </Form.Control.Feedback>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col md={12}>
                        <Button
                          type="submit"
                          variant="success"
                          className="w-100"
                        >
                          Register Now
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Spin>

                <div className="text-center my-4 fs-6">
                  Already registered?{" "}
                  <Link to="/sign-in" className="">
                    Sign In
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      <PsModalWindow
        show={showMobileExitAlert}
        onHide={(e) => setShowMobileExistAlert(false)}
        size="md"
        title="Mobile number exist"
      >
        <MobileNumberExistAlertMessage />
      </PsModalWindow>
    </>
  );
};
/* {courseList &&
                                courseList.length > 0 &&
                                courseList.map((item) => (
                                  <option value={item.id}>
                                    {item.degreename + " - " + item.name}
                                  </option>
                                ))} */
export default Registration;
